import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores';
import { fetchWrapper } from '@/helpers';
const baseUrl = `${import.meta.env.VITE_API_URL}/tokensets`;

export const useTokensetsStore = defineStore(
    'toketensets',
    () => {
        const tokensets = ref([]);
        const tokenset = ref({});
        const areLoaded = ref(false);
        const getTokensets = async () => {
            areLoaded.value = false;
            try {
                tokensets.value = await fetchWrapper.get(baseUrl);
            } catch (error) {
                console.error('Failed to fetch tokensets:', error);
            } finally {
                areLoaded.value = true;
            }
        };
        async function getTokenset(id) {
            try {
                tokenset.value = await fetchWrapper.get(`${baseUrl}/${id}`);
                let tokesetsIndex = tokensets.value.findIndex((tokenset) => tokenset._id === id);
                tokensets.value[tokesetsIndex] = tokenset.value;
            } catch (error) {
                throw new Error('Failed to add connector');
            }
        }
        async function createToken(tokenParam) {
            try {
                tokenset.value = await fetchWrapper.post(`${baseUrl}/addtokenset`, tokenParam);
                tokensets.value.push(tokenset.value);
            } catch (error) {
                throw new Error('Failed to add connector');
            }
        }
        // normal just lastest token
        async function updateToken(id, tokenParam) {
            try {
                tokenset.value = await fetchWrapper.patch(`${baseUrl}/${id}`, tokenParam);
                let tokesetsIndex = tokensets.value.findIndex((tokenset) => tokenset._id === id);
                tokensets.value[tokesetsIndex] = tokenset.value;
            } catch (error) {
                throw new Error('Failed to add connector');
            }
        }

        // Runs the very first time the store is used. i.e., when the store is initialized.
        getTokensets();
        return {
            tokensets,
            tokenset,
            getTokensets,
            getTokenset,
            createToken,
            updateToken,
            areLoaded
        };
    },
    {
        persist: true
    }
);
