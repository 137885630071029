import { ref } from "vue";
import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAlertStore } from '@/stores';
const baseUrl = `${import.meta.env.VITE_API_URL}/responses`;


export const useResponsesStore = defineStore(
    'responses',
    () => {
        const responses = ref([])
        const selectedResponse = ref({})
        const getResponses = async () => {
            try {
                responses.value = await fetchWrapper.get(baseUrl)
            } catch (error) {
                const alertStore = useAlertStore()
                alertStore.error(`Failed to fetch responses: ${error.message}`)
            }
        }
        return {
            responses,
            selectedResponse,
            getResponses
        }
    },
    {
        persist: true,
    },
)
