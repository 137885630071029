import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import { useAuthStore, useConnectorsStore } from '@/stores';
import { fetchWrapper, manageResult } from '@/helpers';
const baseUrl = `${import.meta.env.VITE_API_URL}/models`;

export const useModelsStore = defineStore(
    'models',
    () => {
        const models = ref([]);
        const model = ref({});
        const modelPopulated = ref({});
        const response = ref({});
        const decision = ref({});
        const up2tommodels = ref([]);
        const selectedClusterID = ref('');
        const isLoaded = ref(false);
        const loadingNewModels = ref(false);
        const areLoaded = ref(false);
        const modelMessages = ref([]);
        const availableInputs = ref([]);
        let modelMessageCount = ref(0);
        const modelActivePanel = ref(0);
        function showSuccessMessage(message, severity = 'success') {
            modelMessages.value = [{ severity: severity, content: message, id: modelMessageCount.value++ }];
            modelActivePanel.value = 2;
        }
        async function init() {
            isLoaded.value = false;
            selectedClusterID.value = '';
        }
        async function getModels() {
            areLoaded.value = false;
            try {
                //    init();
                models.value = await fetchWrapper.get(`${baseUrl}`);
                areLoaded.value = true;
            } catch (error) {
                console.log('error', error);
            }
        }
        async function refreshModelById(id) {
            isLoaded.value = false;
            // console.log('Moddel.vue refreshModel _id', id);
            try {
                model.value = models.value.find((m) => m._id === id);
                // refreshmodelMetaData();
                await getModelPopulated(id);
                await manageInputs();
                isLoaded.value = true;
            } catch (error) {
                console.log('Mdddel.vue error _id', error);
            }
        }
        async function refreshModels(id) {
            // console.log('modelsStore refreshModels _id', id);
            let modelIndex = models.value.findIndex((model) => model._id === id);
            models.value[modelIndex] = model.value;
        }
        async function getModel(id) {
            isLoaded.value = false;
            //    inputSelectionMade.value = false;
            //    console.log('Mdddel.vue getModel _id', id);
            //   selectedInput.value = {};
            try {
                model.value = await fetchWrapper.get(`${baseUrl}/${id}`);
                await refreshModels(id);
                await getModelPopulated(id);
                // refreshmodelMetaData();
                await manageInputs();
                isLoaded.value = true;
            } catch (error) {
                console.log('error', error);
            }
        }
        async function getModelPopulated(id) {
            try {
                const populatedData = await fetchWrapper.get(`${baseUrl}/populated/${id}`);
                const lastResponse = populatedData.attributes.response[0] || {};
                const lastDecision = populatedData.attributes.decision[0] || {};
                decision.value = lastDecision;
                modelPopulated.value = populatedData;
                response.value = lastResponse;
            } catch (error) {
                console.log('error', error);
            }
        }
        async function getUp2tomModels(userid, up2tomtoken) {
            loadingNewModels.value = true;
            let modelParam = {};
            modelParam.userid = userid;
            modelParam.up2tomtoken = up2tomtoken;
            try {
                console.log('getUp2tomModels modelParam', modelParam);
                up2tommodels.value = await fetchWrapper.post(`${baseUrl}/up2tommodels`, modelParam);
            } catch (error) {
                console.log('error', error);
            }
            loadingNewModels.value = false;
        }
        async function addModel(modelNew) {
            init();
            isLoaded.value = true;
            try {
                models.value = await fetchWrapper.post(`${baseUrl}/addmodel`, modelNew);
                showSuccessMessage('up2tom Model added to e2Integrate');
            } catch (error) {
                console.log('error', error);
            }
        }
        async function _delete(id) {
            models.value.find((x) => x._id === id).isDeleting = true;
            await fetchWrapper.delete(`${baseUrl}/${id}`);
            models.value = models.value.filter((x) => x._id !== id);
        }
        async function updateModel(id, modelParam) {
            model.value = await fetchWrapper.patch(`${baseUrl}/${id}`, modelParam);
            await refreshModels(id);
        }
        async function changeToWebhook(connector_id, inputname) {
            const connectorsStore = useConnectorsStore();
            let modelParam = {};
            modelParam.model_id = model.value._id;
            modelParam.connector_id = connector_id;
            modelParam.name = inputname;
            try {
                // model.service get connector and model, retrieve direct ObjectId
                // deallocates connector to model, saves connector, then findByIDUpdate of model
                // to ensure both date object is correct and proper ObjecId's referenced on both
                model.value = await fetchWrapper.post(`${baseUrl}/deallocateapi`, modelParam);
                await refreshModels(model.value._id);
                await getModelPopulated(model.value._id);
                await connectorsStore.getConnector(connector_id);
                showSuccessMessage('Webhook Activated');
            } catch (error) {
                console.log('error', error);
            }
        }
        async function change2API(connector_id, inputname) {
            const connectorsStore = useConnectorsStore();
            //   inputSelectionMade.value = false;
            let modelParam = {};
            modelParam.model_id = model.value._id;
            modelParam.connector_id = connector_id;
            modelParam.name = inputname;
            //     changetoAPI.value = false;
            try {
                // model.service get connector and model, retrieve direct ObjectId
                // allocates connector to model, saves connector, then findByIDUpdate of model
                // to ensure both date object is correct and proper ObjecId's referenced on both
                model.value = await fetchWrapper.post(`${baseUrl}/allocateapi`, modelParam);
                await refreshModels(model.value._id);
                await getModelPopulated(model.value._id);
                await connectorsStore.getConnector(connector_id);
                showSuccessMessage('Connector Allocated');
            } catch (error) {
                console.log('error', error);
            }
        }
        async function unassignCluster(model_id, connector_id) {
            const connectorsStore = useConnectorsStore();
            let modelParam = {};
            modelParam['model_id'] = model_id;
            modelParam['connector_id'] = connector_id;
            try {
                model.value = await fetchWrapper.post(`${baseUrl}/unassigncluster`, modelParam);
                await connectorsStore.getConnector(connector_id);
                connectorsStore.refreshConnectors(connector_id);
                await refreshModels(model.value._id);
                // refreshmodelMetaData();
                await getModelPopulated(model.value._id);
                showSuccessMessage('Decision Cluster unassinged from Model ');
            } catch (error) {
                console.log('error', error);
            }
        }
        async function assignCluster(model_id, connector_id, modelid) {
            const connectorsStore = useConnectorsStore();
            let modelParam = {};
            modelParam['model_id'] = model_id;
            modelParam['connector_id'] = connector_id;
            modelParam['modelid'] = modelid;
            try {
                model.value = await fetchWrapper.post(`${baseUrl}/assigncluster`, modelParam);
                connectorsStore.getConnector(connector_id);
                selectedClusterID.value = '';
                await refreshModels(model.value._id);
                // refreshmodelMetaData();
                await getModelPopulated(model.value._id);
                showSuccessMessage('Decision Cluster attached to Model ');
            } catch (error) {
                console.log('error', error);
            }
        }
        async function testAllConnectors(model_id) {
            const authStore = useAuthStore();
            const userid = authStore.user.userid;
            try {
                let testType = 'model';
                let postParam = {};
                postParam.userid = userid;
                postParam.model_id = model_id;
                console.log('test all inputs params = ', postParam);
                let testResult = await fetchWrapper.post(`${baseUrl}/testall`, postParam);
                console.log('testAllConnectors', testResult);
                manageResult(testType, testResult);
                await getModel(model_id);
            } catch (error) {
                console.log('testAllConnectors Error = ', error);
            }
        }
        async function fullCycleTest() {
            const authStore = useAuthStore();
            const userid = authStore.user.userid;
            try {
                let testType = 'model';
                let postParam = {};
                postParam.userid = userid;
                postParam.model_id = model.value._id;
                console.log('fullCycleTest params = ', postParam);
                let testResult = await fetchWrapper.post(`${baseUrl}/completecycletest`, postParam);
                console.log('fullCycleTest', testResult);
                manageResult(testType, testResult);
                await getModel(model.value._id);
            } catch (error) {
                console.log('fullCycleTest Error = ', error);
            }
        }
        async function clusterTest(model_id, cluster_id, batchRefNumber, executeActions) {
            try {
                const authStore = useAuthStore();
                const userid = authStore.user.userid;
                //   console.log('clusterTest model', userid, model_id, cluster_id, batchRefNumber, executeActions);
                let testType = 'cluster';
                let modelParam = {};
                modelParam['userid'] = userid;
                modelParam['model_id'] = model_id;
                modelParam['cluster_id'] = cluster_id;
                modelParam['batchRefNumber'] = batchRefNumber;
                modelParam['executeActions'] = executeActions;
                //  modelParam['renewOthers'] = renewOthers;
                const testResult = await fetchWrapper.post(`${baseUrl}/clustertest`, modelParam);
                //   console.log('clusterTest Result', testResult);
                manageResult(testType, testResult);
            } catch (error) {
                console.log('clusterTest Error = ', error);
            }
        }
        // };
        async function genAllAPIDocs() {}
        async function manageInputs() {
            const inputArray = ['clientRef'];
            for (let i = 0; i < totalInputs.value; i++) {
                const inputName = model.value.attributes.metadata.attributes[i].name;
                inputArray.push(inputName);
            }
            availableInputs.value = inputArray;
        }
        const totalInputs = computed(() => {
            return model.value === undefined ? 0 : model.value.attributes === undefined ? 0 : Object.keys(model.value.attributes.metadata.attributes).length;
        });
        const hasClusterConnector = computed(() => {
            return model.value.attributes === undefined ? false : model.value.attributes.connector == null ? false : true;
        });
        const hasLogs = computed(() => {
            return model.value.attributes === undefined ? false : model.value.attributes.lastLogRef !== undefined ? true : false;
        });
        const decisionAvailable = computed(() => {
            return Object.keys(decision.value).length > 0;
        });
        const errorOnResult = computed(() => {
            return response.value.code === undefined ? false : response.value.code > 399 || response.value.code < 0 ? true : false;
        });
        const clusterSelected = computed(() => {
            return !(selectedClusterID.value == '');
        });
        // const totalInputs = computed(() => {
        //     if (model.value.attributes === undefined) {
        //         return 0;
        //     } else {
        //         return Object.keys(model.value.attributes.metadata.attributes).length;
        //     }
        // });
        // const hasClusterConnector = computed(() => {
        //     if (model.value.attributes === undefined) {
        //         return false;
        //     } else if (model.value.attributes.connector == null) {
        //         return false;
        //     } else {
        //         return true;
        //     }
        // });
        // const hasLogs = computed(() => {
        //     if (model.value.attributes === undefined) {
        //         return false;
        //     } else if (model.value.attributes.lastLogRef !== undefined) {
        //         return true;
        //     } else {
        //         return false;
        //     }
        // });
        // const errorOnResult = computed(() => {
        //     if (response.value.code === undefined) {
        //         return false;
        //     }
        //     if (response.value.code > 399 || response.value.code < 0) {
        //         return true;
        //     } else {
        //         return false;
        //     }
        // });
        getModels();
        return {
            models,
            model,
            modelPopulated,
            decision,
            getModels,
            getModel,
            getModelPopulated,
            _delete,
            updateModel,
            init,
            changeToWebhook,
            change2API,
            refreshModels,
            refreshModelById,
            hasClusterConnector,
            hasLogs,
            decisionAvailable,
            fullCycleTest,
            totalInputs,
            selectedClusterID,
            testAllConnectors,
            genAllAPIDocs,
            assignCluster,
            unassignCluster,
            clusterTest,
            isLoaded,
            areLoaded,
            up2tommodels,
            getUp2tomModels,
            addModel,
            errorOnResult,
            response,
            clusterSelected,
            loadingNewModels,
            modelMessages,
            modelMessageCount,
            modelActivePanel,
            availableInputs
        };
    },
    {
        persist: true
    }
);
