<script setup>
import { onBeforeMount, onMounted, ref, watch, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useDateFormat } from '@/composables';
import { VueJsonPretty } from '@/components';
import { useLayout } from '@/layout/composables/layout';
import { FilterMatchMode } from 'primevue/api';
import { router } from '@/router';
import { useModelsStore, useActionsStore, useConnectorsStore, useConnectortemplatesStore, useActiontemplatesStore, useDecisionsStore, useLogsStore, useAuthStore, useTokensetsStore } from '@/stores';
const decisionsStore = useDecisionsStore();
const modelsStore = useModelsStore();
const actionsStore = useActionsStore();
const tokensetsStore = useTokensetsStore();
const connectorsStore = useConnectorsStore();
const actiontemplatesStore = useActiontemplatesStore();
const connectortemplatesStore = useConnectortemplatesStore();
const logsStore = useLogsStore();
const { decisions, statistics, selectedDecision, areLoaded, isLoaded } = storeToRefs(decisionsStore);
const { logs } = storeToRefs(logsStore);
const { getLogs } = logsStore;
const { getModels } = modelsStore;
const { getTokensets } = tokensetsStore;
const { getConnectors } = connectorsStore;
const { getActions } = actionsStore;
const { getDecisions } = decisionsStore;
const { getConnectortemplates } = connectortemplatesStore;
const { getActiontemplates } = actiontemplatesStore;
const authStore = useAuthStore();
const decisionsPrettyName = ref('Selected Decision');
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
});

// onBeforeMount(async () => {
//     areLoaded.value = false;
//     await getDecisions();
//     console.log('dashboard decision before mount and stores initialized');
//     // chartData.value = setChartData();
//     // chartOptions.value = setChartOptions();
// });

onMounted(async () => {
    areLoaded.value = false;
    getModels();
    getConnectors();
    getActions();
    getLogs();
    await getDecisions();
    getConnectortemplates();
    getActiontemplates();
    getTokensets();
    console.log('dashboard on mount and stores initialized');
    chartData.value = setChartData();
    chartOptions.value = setChartOptions();
});

// const e2techUserIds = new Set(['6523d27ccd39a6724b4222ee', '64fd7a269ce9e2059b5c1345']);
// const isE2Tech =  computed(() => {
//     const { userid } = authStore.user;
//     return e2techUserIds.has(userid);
// });

const to2decimals = (value) => {
    return parseFloat(value).toFixed(2);
};

const images = ref([
    {
        itemImageSrc: '/demo/images/galleria/galleria1.jpg',
        thumbnailImageSrc: '/demo/images/galleria/galleria1s.jpg',
        alt: 'TOM Analysis',
        title: 'TOM Analysis'
    },
    {
        itemImageSrc: '/demo/images/galleria/galleria2.jpg',
        thumbnailImageSrc: '/demo/images/galleria/galleria2s.jpg',
        alt: 'Teach TOM',
        title: 'Teach TOM'
    },
    {
        itemImageSrc: '/demo/images/galleria/galleria11.jpg',
        thumbnailImageSrc: '/demo/images/galleria/galleria11s.jpg',
        alt: 'Teach TOM',
        title: 'Teach TOM'
    },
    {
        itemImageSrc: '/demo/images/galleria/galleria12.jpg',
        thumbnailImageSrc: '/demo/images/galleria/galleria12s.jpg',
        alt: 'Teach TOM',
        title: 'Teach TOM'
    },
    {
        itemImageSrc: '/demo/images/galleria/galleria3.jpg',
        thumbnailImageSrc: '/demo/images/galleria/galleria3s.jpg',
        alt: 'Teach TOM',
        title: 'Teach TOM'
    },
    {
        itemImageSrc: '/demo/images/galleria/galleria4.jpg',
        thumbnailImageSrc: '/demo/images/galleria/galleria4s.jpg',
        alt: 'Teach TOM',
        title: 'Teach TOM'
    },
    {
        itemImageSrc: '/demo/images/galleria/galleria5.jpg',
        thumbnailImageSrc: '/demo/images/galleria/galleria5s.jpg',
        alt: 'Teach TOM',
        title: 'Teach TOM'
    },
    {
        itemImageSrc: '/demo/images/galleria/galleria6.jpg',
        thumbnailImageSrc: '/demo/images/galleria/galleria6s.jpg',
        alt: 'Teach TOM',
        title: 'Teach TOM'
    },
    {
        itemImageSrc: '/demo/images/galleria/galleria7.jpg',
        thumbnailImageSrc: '/demo/images/galleria/galleria7s.jpg',
        alt: 'Teach TOM',
        title: 'Teach TOM'
    },
    {
        itemImageSrc: '/demo/images/galleria/galleria8.jpg',
        thumbnailImageSrc: '/demo/images/galleria/galleria8s.jpg',
        alt: 'Teach TOM',
        title: 'Teach TOM'
    },
    {
        itemImageSrc: '/demo/images/galleria/galleria9.jpg',
        thumbnailImageSrc: '/demo/images/galleria/galleria9s.jpg',
        alt: 'Teach TOM',
        title: 'Teach TOM'
    },
    {
        itemImageSrc: '/demo/images/galleria/galleria10.jpg',
        thumbnailImageSrc: '/demo/images/galleria/galleria10s.jpg',
        alt: 'Teach TOM',
        title: 'Teach TOM'
    }
]);

const responsiveOptions = ref([
    {
        breakpoint: '1300px',
        numVisible: 4
    },
    {
        breakpoint: '575px',
        numVisible: 1
    }
]);

const { isDarkTheme } = useLayout();

const items = ref([
    { label: 'Add New', icon: 'pi pi-fw pi-plus' },
    { label: 'Remove', icon: 'pi pi-fw pi-minus' }
]);
const lineOptions = ref(null);
const logsAvailable = (value) => {
    if (logs.value.some((e) => e.meta.stack.logRefNumber === value)) {
        return true;
    } else {
        return false;
    }
};

const rowClass = (data) => {
    return [
        {
            'text-green-500': data.attributes['meets-confidence'] == true,
            'text-orange-500': data.attributes['meets-confidence'] == false
        }
    ];
};

const latestLogs = (id) => {
    console.log('latestLogs logReference = ', id);
    router.push({ path: `/logs/logref/${id}` });
};
const chartData = ref();
const chartOptions = ref();

const setChartData = () => {
    const documentStyle = getComputedStyle(document.documentElement);

    return {
        datasets: [
            {
                data: [statistics.value.decisionsSource.cluster, statistics.value.decisionsSource.connector, statistics.value.decisionsSource.webhook, statistics.value.decisionsSource.webhookfallback, statistics.value.decisionsSource.webhookupdate],
                backgroundColor: [
                    documentStyle.getPropertyValue('--pink-500'),
                    documentStyle.getPropertyValue('--gray-500'),
                    documentStyle.getPropertyValue('--orange-500'),
                    documentStyle.getPropertyValue('--purple-500'),
                    documentStyle.getPropertyValue('--cyan-500')
                ],
                label: 'My dataset'
            }
        ],
        labels: ['Cluster', 'Connector', 'Webhook', 'Webhook-Fallback', 'Webhook-Update']
    };
};
const setChartOptions = () => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

    return {
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            r: {
                grid: {
                    color: surfaceBorder
                }
            }
        }
    };
};

const applyLightTheme = () => {
    lineOptions.value = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            },
            y: {
                ticks: {
                    color: '#495057'
                },
                grid: {
                    color: '#ebedef'
                }
            }
        }
    };
};

const applyDarkTheme = () => {
    lineOptions.value = {
        plugins: {
            legend: {
                labels: {
                    color: '#ebedef'
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: '#ebedef'
                },
                grid: {
                    color: 'rgba(160, 167, 181, .3)'
                }
            },
            y: {
                ticks: {
                    color: '#ebedef'
                },
                grid: {
                    color: 'rgba(160, 167, 181, .3)'
                }
            }
        }
    };
};

watch(
    isDarkTheme,
    (val) => {
        if (val) {
            applyDarkTheme();
        } else {
            applyLightTheme();
        }
    },
    { immediate: true }
);
</script>

<template>
    <!-- loading .... -->
    <ProgressSpinner v-if="!areLoaded" aria-label="Loading" />
    <!-- only when actions loaded  -->
    <template v-if="areLoaded">

        <div class="grid">
            <div class="col-12 lg:col-6 xl:col-3">
                <div class="card mb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-500 font-medium mb-3">Digital Twins Active</span>
                            <div class="text-900 font-medium text-xl">27</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-user-plus text-blue-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium">2 new </span>
                    <span class="text-500">since last visit</span>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-3">
                <div class="card mb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-500 font-medium mb-3">Decisions</span>
                            <div class="text-900 font-medium text-xl">{{ statistics.decisions }}</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-question text-orange-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium">requested </span>
                    <span class="text-500">in last 24Hrs</span>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-3">
                <div class="card mb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-500 font-medium mb-3">Orchestrations</span>
                            <div class="text-900 font-medium text-xl">{{ statistics.orchestrations }}</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-share-alt text-cyan-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium">successfully requested </span>
                    <span class="text-500">in last 30 days</span>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-3">
                <div class="card mb-0">
                    <div class="flex justify-content-between mb-3">
                        <div>
                            <span class="block text-500 font-medium mb-3">Actions</span>
                            <div class="text-900 font-medium text-xl">{{ statistics.actionsRequested}}</div>
                        </div>
                        <div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-spin pi-cog text-purple-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium">successfully </span>
                    <span class="text-500">executed with 24Hrs</span>
                </div>
            </div>

            <div class="col-12 xl:col-6">
                <div class="card flex justify-content-center">
                    <Chart type="polarArea" :data="chartData" :options="chartOptions" class="w-full md:w-30rem" />
                </div>
                <div class="card">
                    <div class="flex align-items-center justify-content-between mb-4">
                        <h5>Notifications</h5>
                        <div>
                            <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-plain p-button-rounded" @click="$refs.menu1.toggle($event)"></Button>
                            <Menu ref="menu1" :popup="true" :model="items"></Menu>
                        </div>
                    </div>

                    <span class="block text-600 font-medium mb-3">TODAY</span>
                    <ul class="p-0 mx-0 mt-0 mb-4 list-none">
                        <li class="flex align-items-center py-2 border-bottom-1 surface-border">
                            <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                                <i class="pi pi-question text-xl text-blue-500"></i>
                            </div>
                            <span class="text-900 line-height-3"
                                >Model - ServiceNow Audit
                                <span class="text-700">request AI Model Decisions in last 24Hrs <span class="text-blue-500">79</span></span>
                            </span>
                        </li>
                        <li class="flex align-items-center py-2">
                            <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0">
                                <i class="pi pi-download text-xl text-orange-500"></i>
                            </div>
                            <span class="text-700 line-height-3">Data download requested by <span class="text-blue-500 font-medium">Andy Dukes</span> has been initiated.</span>
                        </li>
                    </ul>

                    <span class="block text-600 font-medium mb-3">YESTERDAY</span>
                    <ul class="p-0 m-0 list-none">
                        <li class="flex align-items-center py-2 border-bottom-1 surface-border">
                            <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-blue-100 border-circle mr-3 flex-shrink-0">
                                <i class="pi pi-download text-xl text-blue-500"></i>
                            </div>
                            <span class="text-900 line-height-3"
                                >Paul Tynan
                                <span class="text-700">has downloaded <span class="text-blue-500"> Audit data</span></span>
                            </span>
                        </li>
                        <li class="flex align-items-center py-2 border-bottom-1 surface-border">
                            <div class="w-3rem h-3rem flex align-items-center justify-content-center bg-pink-100 border-circle mr-3 flex-shrink-0">
                                <i class="pi pi-question text-xl text-pink-500"></i>
                            </div>
                            <span class="text-900 line-height-3"
                                >Model - Server Load
                                <span class="text-700">requested AI Decision</span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-12 xl:col-6">
                <div  v-if="authStore.isE2Tech" class="card">
                    <Galleria :value="images" :responsiveOptions="responsiveOptions" :numVisible="5" containerStyle="max-width: 640px">
                        <template #item="slotProps">
                            <img :src="slotProps.item.itemImageSrc" :alt="slotProps.item.alt" style="width: 100%" />
                        </template>
                        <template #thumbnail="slotProps">
                            <img :src="slotProps.item.thumbnailImageSrc" :alt="slotProps.item.alt" />
                        </template>
                    </Galleria>
                </div>

                <div class="card">
                    <h5>Recent Decisions</h5>
                    <DataTable
                        v-model:selection="selectedDecision"
                        :value="decisions"
                        selectionMode="single"
                        dataKey="_id"
                        paginator
                        stripedRows
                        :rows="5"
                        :rowsPerPageOptions="[5, 10, 20]"
                        tableStyle="min-width: 50rem"
                        filterDisplay="row"
                        :loading="!areLoaded"
                    >
                        <Column field="modelid" header="Model"></Column>
                        <Column field="attributes.decision" header="Decision" sortable></Column>
                        <Column field="createdAt" header="Date" sortable>
                            <template #body="{ data }">
                                {{ useDateFormat(data.createdAt) }}
                            </template>
                        </Column>
                        <Column field="attributes.confidence" header="Confidence">
                            <template #body="{ data }"> {{ to2decimals(data.attributes.confidence * 100) }} % </template>
                        </Column>
                        <Column style="flex: 0 0 3rem">
                            <template #body="{ data }">
                                <Button type="button" style="font-size: 1rem" :disabled="!logsAvailable" label="Logs" @click="latestLogs(data.logRefNumber)" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
                <VueJsonPretty v-if="selectedDecision != null" :selectedItem="selectedDecision" :jsonPrettyName="decisionsPrettyName" />
            </div>
        </div>

    </template>
</template>    
<style>
.field > label {
    display: inline-block;
    margin-bottom: 0.5rem;
    color: var(--primary-color);
}

.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 0.75rem 1rem;
    border: 1px solid #e2e8f0;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: var(--primary-200);
    background: #ffffff;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
}

.p-datatable .p-datatable-header {
    background: #ffffff;
    color: var(--primary-400);
    border: 1px solid #e2e8f0;
    border-width: 0 0 1px 0;
    padding: 0.75rem 1rem;
    font-weight: 600;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1.125rem 1.125rem 1.125rem 1.125rem;
    border: 0 none;
    color: var(--primary-color);
    background: #ffffff;
    font-weight: 600;
    border-radius: 6px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, outline-color 0.2s;
    outline-color: transparent;
}
</style>
