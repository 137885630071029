import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useAuthStore, useModelsStore, useTokensetsStore } from '@/stores';
import { fetchWrapper, manageResult, manageResponse } from '@/helpers';
import { useValidHttpUrl } from '@/composables';
import Mustache from 'mustache';
import { router } from '@/router';
const baseUrl = `${import.meta.env.VITE_API_URL}/connectors`;

export const useConnectorsStore = defineStore(
    'connectors',
    () => {
        const connectors = ref([]);
        const connector = ref({});
        const connectorPopulated = ref({});
        const connectorSelected = ref(false);
        const hostChanged = ref(false);
        const pathChanged = ref(false);
        const isLoaded = ref(false);
        const areLoaded = ref(false);
        const renderOK = ref(true);
        const testAllowed = ref(false);
        const discoveryURLOK = ref(true);
        const compareHost = ref('');
        const comparePath = ref('');
        const authSettingType = ref('');
        const newAuthSettingType = ref('');
        const response = ref({});
        const parsedVariables = ref({});
        const availableInputs = ref([]);
        const selectedConnectorID = ref('');
        const testdata = ref({});
        const connectorMessages = ref([]);
        let connectorMessageCount = ref(0);
        const connectorActivePanel = ref(0);
        const init = () => {
            // connector.value = {};
            // response.value = {};
            // connectorPopulated.value = {};
            // connectorSelected.value = false;
            // isLoaded.value = false;
            // selectedConnectorID.value = '';
        };
        function showSuccessMessage(message, severity = 'success') {
            connectorMessages.value = [{ severity: severity, content: message, id: connectorMessageCount.value++ }];
            connectorActivePanel.value = 1;
        }
        async function getConnectors() {
            areLoaded.value = false;
            try {
                connectors.value = await fetchWrapper.get(`${baseUrl}`);
                areLoaded.value = true;
            } catch (error) {
                console.error('Failed to fetch connectors:', error);
            }
        }
        function refreshConnectors(id) {
            console.log('connectorsStore  _id', id);
            let connectorsIndex = connectors.value.findIndex((connector) => connector._id === id);
            connectors.value[connectorsIndex] = connector.value;
        }
        async function getConnector(connectorId) {
            const tokensetsStore = useTokensetsStore();
            isLoaded.value = false;
            console.log('connector store getConnector connectorId', connectorId);
            try {
                connector.value = await fetchWrapper.get(`${baseUrl}/${connectorId}`);
                console.log('getConnector refreshConnectors');
                refreshConnectors(connector.value._id);
                await tokensetsStore.getTokensets();
                console.log('getConnector got getTokensets');
                await getConnectorPopulated(connectorId);
                await setupAPI();
                console.log('getConnector returning to Connector.vue');
                isLoaded.value = true;
            } catch (error) {
                console.error('Failed to refresh connector', error);
            }
        }
        async function updateConnector(connectorId, updatedConnector) {
            connector.value = await fetchWrapper.patch(`${baseUrl}/${connectorId}`, updatedConnector);
            refreshConnectors(connector.value._id);
            await getConnectorPopulated(connectorId);
        }
        async function refreshConnectorById(connectorId) {
            isLoaded.value = false;
            try {
                connector.value = connectors.value.find((c) => c._id === connectorId);
                await getConnectorPopulated(connectorId);
                await setupAPI();
                isLoaded.value = true;
            } catch (error) {
                console.error('Failed to refresh connector', error);
            }
        }
        async function getConnectorPopulated(connectorId) {
            try {
                const populatedData = await fetchWrapper.get(`${baseUrl}/populated/${connectorId}`);
                const lastResponse = populatedData.response[0] || {};
                //    const token = populatedData.tokenset || {};
                connectorPopulated.value = populatedData;
                response.value = lastResponse;
                //   token.value = token;
            } catch (error) {
                console.error('Failed to fetch getConnectorPopulated:', error);
            }
        }
        async function deleteConnector(connectorId) {
            try {
                await fetchWrapper.delete(`${baseUrl}/${connectorId}`);
                connectors.value = connectors.value.filter((connector) => connector._id !== connectorId);
                await router.push('/connectors');
            } catch (error) {
                throw new Error('Failed to delete connector');
            }
        }
        async function addConnector(connectorData) {
            try {
                const createdConnector = await fetchWrapper.post(`${baseUrl}/addconnector`, connectorData);
                connectors.value.push(createdConnector);
                showSuccessMessage('Connector Added');
            } catch (error) {
                throw new Error('Failed to add connector');
            }
        }
        async function clone(id) {
            try {
                areLoaded.value = false;
                let cloneParam = {};
                cloneParam = connector.value;
                let cloneResult = {};
                cloneResult = await fetchWrapper.post(`${baseUrl}/${id}/connectorclone`, cloneParam);
                connectors.value = connectors.value.push(cloneResult);
                showSuccessMessage('Connector Cloned');
                areLoaded.value = true;
                router.push(`/connectors`);
            } catch (error) {
                console.error('Failed to clone connector:', error);
            }
        }
        async function checkConfig() {
            connectorMessages.value = [];
            testAllowed.value = true;
            if (authSettingType.value == 'bearer') {
                let authToken = renderMustache(connector.value.item.request.auth.bearer[0].value);
                parsedVariables.value.authHeaderKey = 'Authorization';
                parsedVariables.value.authHeaderValue = 'Bearer ' + authToken;
            }
            if (authSettingType.value == 'basic') {
                let basicPassword = renderMustache(connector.value.item.request.auth.basic[passwordIndex.value].value);
                let authToken = basicAuthEncoded(connector.value.item.request.auth.basic[usernameIndex.value].value, basicPassword);
                parsedVariables.value.authHeaderKey = 'Authorization';
                parsedVariables.value.authHeaderValue = 'Basic ' + authToken;
            }
            if (authSettingType.value == 'apikey') {
                let apiHeaderKey = renderMustache(connector.value.item.request.auth.apikey[apikeyIndex.value].value);
                let apiHeaderValue = renderMustache(connector.value.item.request.auth.apikey[apivalueIndex.value].value);
                parsedVariables.value.authHeaderKey = apiHeaderKey;
                parsedVariables.value.authHeaderValue = apiHeaderValue;
                console.log('connector headers ', apiHeaderKey, apiHeaderValue);
            }
            let targetURL = renderMustache(connector.value.item.request.url.raw);
            if (bodyJson.value) {
                let targetBody = renderMustache(connector.value.item.request.body.raw);
                connector.value.item.request.targetBody = targetBody;
            }
            connector.value.item.request.targetURL = targetURL;
            console.log('connectorItem.vue checkConfig targetURL targetBody   ', connector.value.item.request.targetURL, connector.value.item.request.targetBody);
            await saveConnector(connector.value._id, connector.value);
            connectorMessages.value = [
                { severity: 'success', content: 'Connector Configuration Checked & Saved', id: connectorMessageCount.value++ },
                { severity: 'info', content: 'Please contine to Update & Test', id: connectorMessageCount.value++ }
            ];
            connectorActivePanel.value = 1;
        }
        async function connectorTest(id) {
            const authStore = useAuthStore();
            const userid = authStore.user.userid;
            try {
                let connectorParam = {};
                connectorParam.userid = userid;
                connector.value = await fetchWrapper.post(`${baseUrl}/test/${id}`, connectorParam);
                refreshConnectors(id);
                await getConnectorPopulated(id);
                await manageResponse('connector', response.value);
                await manageFormats();
                await setupAPI();
                testAllowed.value = false;
                isLoaded.value = true;
            } catch (error) {
                console.error('Failed to fetch connector:', error);
            }
        }
        async function saveConnector(id, connectorParam) {
            console.log('saveConnector ', id);
            connector.value = await fetchWrapper.post(`${baseUrl}/${id}`, connectorParam);
            refreshConnectors(id);
            await getConnectorPopulated(id);
            await setupAPI();
        }
        async function saveHost() {
            const updatedHost = renderMustache(compareHost.value);
            const url = `${connector.value.item.request.url.protocol}://${updatedHost}`;
            if (!useValidHttpUrl(url)) {
                showSuccessMessage('Invalid new URL', 'error');
                return;
            }
            const hostSegments = updatedHost.split('.');
            connector.value.item.request.url.host = hostSegments;

            const updatedConnector = { ...connector.value };
            await saveConnector(connector.value._id, updatedConnector);
            showSuccessMessage('Connector host updated');
        }
        async function savePath() {
            const updatedPath = renderMustache(comparePath.value);
            const fullUrl = `${connector.value.rawBaseUrl}/${updatedPath}`;

            if (!useValidHttpUrl(fullUrl)) {
                showSuccessMessage('ERROR New Path is Invalid', 'error');
            } else {
                const pathSegments = updatedPath.split('/');
                connector.value.item.request.url.path = pathSegments;
                const updatedConnector = { ...connector.value };
                await saveConnector(updatedConnector._id, updatedConnector);
                showSuccessMessage('Success! Connector Url Path Updated');
            }
        }
        async function checkDiscoveryURL() {
            const authStore = useAuthStore();
            const userid = authStore.user.userid;
            const discoveryURL = connector.value.discoveryURL;
            if (!useValidHttpUrl(discoveryURL)) {
                showSuccessMessage('ERROR New Discovery URL is Invalid', 'error');
                discoveryURLOK.value = false;
            } else {
                discoveryURLOK.value = true;
                let postParam = {};
                postParam.discoveryURL = discoveryURL;
                postParam.userid = userid;
                postParam.auth = connector.value.item.request.auth;
                const discoveryResult = await fetchWrapper.post(`${baseUrl}/checkdiscoveryurl/${connector.value._id}`, postParam);
                const { errorStatus, issuer, metadata = 'N/A', errorMsg = 'N/A', auth = [] } = discoveryResult;
                if (errorStatus) {
                    const { code = 'Unknown Error Occured' } = errorMsg;
                    showSuccessMessage('ERROR ' + code, 'error');
                } else {
                    connector.value.item.request.auth = auth;
                    await saveConnector(connector.value._id, connector.value);
                    showSuccessMessage('Success! Connector OAuth2 Discovery Updated');
                }
            }
        }
        async function setupTokenManager() {
            const tokensetsStore = useTokensetsStore();
            try {
                console.log('createTokenManager', connector.value.name);
                let tokenParam = {};
                tokenParam.id_token = connector.value.name;
                tokenParam.access_token = connector.value.lastresult;
                tokenParam.expires_in = 1800;
                tokenParam.expires_at = Date.now() + 1800 * 1000;
                tokenParam.connector = connector.value._id;
                tokenParam.userid = connector.value.userid;
                await tokensetsStore.createToken(tokenParam); // 1800 * 1000
                let connectorParam = {};
                connectorParam._id = connector.value._id;
                connectorParam.tokenset = tokensetsStore.tokenset._id;
                connectorParam.tokenManager = true;
                await updateConnector(connector.value._id, connectorParam);
                isLoaded.value = true;
            } catch (error) {
                console.error('Failed to create token manager:', error);
            }
        }
        async function createTokenClient() {
            try {
                let connectorParam = {};
                connectorParam._id = connector.value._id;
                connectorParam.tokenset = connector.value.tokenset;
                connectorParam.tokenClient = true;
                connectorParam.tokenManager = false;
                connectorParam.tokenVariableKey = connector.value.tokenVariableKey;
                await updateConnector(connector.value._id, connectorParam);
                isLoaded.value = true;
            } catch (error) {
                console.error('Failed to create token manager:', error);
            }
        }
        async function setupAPI() {
            const modelsStore = useModelsStore();
            compareHost.value = connector.value.item.request.url.host.join('.');
            comparePath.value = connector.value.item.request.url.path.join('/');
            hostChanged.value = false;
            pathChanged.value = false;
            authSettingType.value = connector.value.item.request.auth.type;
            newAuthSettingType.value = connector.value.item.request.auth.type;
            let body = {};
            body.mode = 'none';
            if (connector.value.item.request.body === undefined) {
                connector.value.item.request.body = body;
            }
            await manageFormats();
            if (typeof connector.value.lastresultObject === 'object') {
                testdata.value = Object.assign({}, connector.value.lastresultObject);
            }
            if (connectorItemAllocated.value) {
                console.log('get modelstore to getbyid connector.value._modelid', connector.value._modelid);
                await modelsStore.refreshModelById(connector.value._modelid);
            } else {
                console.log('setupAPI connectorItemAllocated = not true');
            }
            isLoaded.value = true;
        }
        async function changeAuth() {
            const authStore = useAuthStore();
            const userid = authStore.user.userid;
            const auth = connector.value.item.request.auth;
            const headers = connector.value.item.request.header;
            const newAuthSetting = newAuthSettingType.value;
            const oldAuthSetting = authSettingType.value;

            try {
                const response = await fetchWrapper.post(`${baseUrl}/changeauth`, {
                    userid,
                    authObj: auth,
                    headerObj: headers,
                    newAuthSetting,
                    oldAuthSetting
                });

                connector.value.item.request.auth = response.newAuthObj;
                authSettingType.value = connector.value.item.request.auth.type;
                newAuthSettingType.value = connector.value.item.request.auth.type;
                showSuccessMessage('Authorization Type Changed. Please enter new Credentials', 'warning');
            } catch (error) {
                console.error('Failed to change auth:', error);
            }
        }
        async function deleteVariable(key) {
            const index = connector.value.variable.findIndex((variable) => variable.key === key);
            connector.value.variable.splice(index, 1);
            showSuccessMessage('HTTP variable deleted. Please save to commit');
            connectorActivePanel.value = 1;
        }
        async function deleteHeader(headerKey) {
            const index = connector.value.item.request.header.findIndex((header) => header.key === headerKey);
            connector.value.item.request.header.splice(index, 1);
            showSuccessMessage('HTTP header deleted. Please click SAVE to commit');
        }
        async function deleteQuery(queryKey) {
            const index = connector.value.item.request.url.query.findIndex((query) => query.key === queryKey);
            connector.value.item.request.url.query.splice(index, 1);
            showSuccessMessage('HTTP query deleted. Please click SAVE to commit');
        }
        async function deleteFormdata(formdataKey) {
            const index = connector.value.item.request.body.formdata.findIndex((formdata) => formdata.key === formdataKey);
            connector.value.item.request.body.formdata.splice(index, 1);
            showSuccessMessage('Formdata field deleted. Please click SAVE to commit');
        }
        async function manageFormats() {
            console.log('decisionCluster ? ', connector.value.decisionCluster);
            if (!itsAnObject.value & testAllowed.value) {
                if (itsAnObject.value && !connector.value.decisionCluster) {
                    if (connector.value.deformat.jsonpathcmd == null) {
                        showSuccessMessage('Test Completed. Enter JsonPath to complete configuration');
                    }
                } else if (itsAString.value && !connector.value.deformat.deformatstatus && !connector.value.decisionCluster) {
                    showSuccessMessage('SUCCESS! Test Completed. Does Result Require De-formatting? YES/NO  Log Reference ' + response.value.logRefNumber);
                } else if (itsAString.value && connector.value.deformat.deformatstatus) {
                    showSuccessMessage('SUCCESS! Tests Completed. Log Reference ' + response.value.logRefNumber);
                }
            }
        }
        function renderMustache(itemToRender) {
            return Mustache.render(itemToRender, connector.value.mustachedata);
        }
        function basicAuthEncoded(email, password) {
            const credentials = `${email}:${password}`;
            return btoa(credentials);
        }
        const clusterTestAllowed = computed(() => {
            const modelsStore = useModelsStore();
            if (Object.keys(filteredInputs.value).length < Object.keys(modelsStore.availableInputs).length) {
                return true;
            } else {
                return false;
            }
        });
        const isAllocated = computed(() => {
            return connector.value._modelid === null;
        });
        const isTokenManager = computed(() => {
            return connector.value.tokenManager === true;
        });
        const isTokenClient = computed(() => {
            return connector.value.tokenClient === true;
        });
        const itsAString = computed(() => {
            return typeof connector.value.lastresultObject === 'object' && typeof connector.value.lastresult === 'string';
        });
        const freeConnectors = computed(() => {
            return connectors.value.filter((connector) => connector._modelid === null);
        });
        const unallocatedClusters = computed(() => {
            return connectors.value.filter((connector) => connector._modelid === null && connector.deformat.decisionCluster == true);
        });
        const deformatConfigured = computed(() => {
            return connector.value === undefined || connector.value == null || isLoaded.value == false ? false : connector.value.deformat.deformatstatus;
        });
        const jsonPathConfigured = computed(() => {
            return connector.value === undefined || connector.value == null || isLoaded.value == false ? false : connector.value.deformat.jsonpath;
        });
        const isBody = computed(() => {
            if (isLoaded.value == false) {
                return false;
            } else {
                return !(connector.value.item.request.body === undefined || connector.value.item.request.body.mode == 'none');
            }
        });
        const postMethod = computed(() => {
            if (isLoaded.value == false) {
                return false;
            } else {
                return connector.value.item.request.method === 'POST';
            }
        });
        const bodyFormdata = computed(() => {
            if (isLoaded.value == false) {
                return false;
            } else {
                return connector.value.item.request.body.mode === 'formdata';
            }
        });
        const bodyJson = computed(() => {
            if (isLoaded.value == false) {
                return false;
            } else {
                return connector.value.item.request.body.mode === 'raw';
            }
        });
        const isProtocol = computed(() => {
            if (isLoaded.value == false) {
                return false;
            } else {
                return !(connector.value.item.request.url.protocol === undefined);
            }
        });
        const itsAnObject = computed(() => {
            if (isLoaded.value == false) {
                return false;
            }
            if (connector.value.lastresultObject === null) {
                return false;
            } else {
                return !(Object.entries(connector.value.lastresultObject).length === 0);
            }
        });
        const responseAvailable = computed(() => {
            return !(response.value._id === undefined);
        });
        const errorOnResult = computed(() => {
            if (response.value.code === undefined) {
                return false;
            }
            if (response.value.code > 399 || response.value.code < 0) {
                return true;
            } else {
                return false;
            }
        });
        const unallocatedCluster = computed(() => {
            if (isLoaded.value == false) {
                return false;
            } else {
                return connector.value.deformat.decisionCluster && connector.value._modelid == null;
            }
        });
        const connectorItemAllocated = computed(() => {
            if (isLoaded.value == false) {
                return false;
            } else {
                return !(connector.value._modelid == null);
            }
        });
        const filteredInputs = computed(() => {
            const modelsStore = useModelsStore();
            return connector.value.deformat === undefined ? modelsStore.availableInputs : modelsStore.availableInputs.filter((e) => connector.value.deformat.clusterConfig.find((obj) => obj.name === e) === undefined);
        });
        const savedInputs = computed(() => {
            if (isLoaded.value == false) {
                return [];
            } else if (Array.isArray(connector.value.deformat.clusterItems) && connector.value.deformat.clusterItems.length) {
                return connector.value.deformat.clusterItems;
            } else {
                return [];
            }
        });
        const savedConfig = computed(() => {
            if (isLoaded.value == false) {
                return [];
            } else if (Array.isArray(connector.value.deformat.clusterConfig) && connector.value.deformat.clusterConfig.length) {
                return connector.value.deformat.clusterConfig;
            } else {
                return [];
            }
        });
        const oauth2PasswordCredentials = computed(() => {
            return connector.value.item === undefined ? false : connector.value.item.request.auth.type != 'oauth2' ? false : connector.value.item.request.auth.oauth2[1].value == 'password_credentials' ? true : false;
        });
        const oauth2ClientCredentials = computed(() => {
            return connector.value.item === undefined ? false : connector.value.item.request.auth.type != 'oauth2' ? false : connector.value.item.request.auth.oauth2[1].value == 'client_credentials' ? true : false;
        });
        const lastresultObject = computed(() => connector.value?.lastresultObject);
        const passwordIndex = computed(() => {
            return connector.value.item === undefined ? 0 : connector.value.item.request.auth.type != 'basic' ? 0 : connector.value.item.request.auth.basic.findIndex((item) => item.key === 'password');
        });
        const usernameIndex = computed(() => {
            return connector.value.item === undefined ? 0 : connector.value.item.request.auth.type != 'basic' ? 0 : connector.value.item.request.auth.basic.findIndex((item) => item.key === 'username');
        });
        const apivalueIndex = computed(() => {
            return connector.value.item === undefined ? 0 : connector.value.item.request.auth.type != 'apikey' ? 0 : connector.value.item.request.auth.apikey.findIndex((item) => item.key === 'value');
        });
        const apikeyIndex = computed(() => {
            return connector.value.item === undefined ? 0 : connector.value.item.request.auth.type != 'apikey' ? 0 : connector.value.item.request.auth.apikey.findIndex((item) => item.key === 'key');
        });
        const availableConnectors = computed(() => {
            return connectors.value.filter((connector) => connector._modelid === null);
        });
        getConnectors();
        // Runs the very first time the store is used. i.e., when the store is initialized.
        return {
            connectors,
            connector,
            connectorPopulated,
            getConnectors,
            getConnector,
            getConnectorPopulated,
            deleteConnector,
            addConnector,
            updateConnector,
            freeConnectors,
            connectorSelected,
            deformatConfigured,
            jsonPathConfigured,
            checkConfig,
            connectorTest,
            isBody,
            hostChanged,
            pathChanged,
            saveHost,
            savePath,
            isProtocol,
            comparePath,
            compareHost,
            discoveryURLOK,
            checkDiscoveryURL,
            authSettingType,
            newAuthSettingType,
            changeAuth,
            deleteVariable,
            deleteHeader,
            deleteQuery,
            deleteFormdata,
            response,
            errorOnResult,
            responseAvailable,
            itsAnObject,
            itsAString,
            unallocatedCluster,
            parsedVariables,
            renderMustache,
            basicAuthEncoded,
            testAllowed,
            connectorItemAllocated,
            saveConnector,
            availableInputs,
            filteredInputs,
            clone,
            isLoaded,
            areLoaded,
            savedInputs,
            savedConfig,
            renderOK,
            oauth2PasswordCredentials,
            oauth2ClientCredentials,
            lastresultObject,
            testdata,
            init,
            selectedConnectorID,
            passwordIndex,
            usernameIndex,
            apivalueIndex,
            apikeyIndex,
            refreshConnectorById,
            connectorMessages,
            connectorMessageCount,
            connectorActivePanel,
            clusterTestAllowed,
            availableConnectors,
            unallocatedClusters,
            refreshConnectors,
            postMethod,
            bodyFormdata,
            bodyJson,
            isTokenManager,
            isTokenClient,
            isAllocated,
            setupTokenManager,
            createTokenClient
        };
    },
    {
        persist: true
    }
);
