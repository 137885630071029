<script setup>
import { ref, onMounted, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useModelsStore, useActionsStore, useConnectorsStore, useAuthStore, useDecisionsStore, useLogsStore, useAlertStore } from '@/stores';
import { VueJsonPretty } from '@/components';
import { useToast } from 'primevue/usetoast';
import { router } from '@/router';
import { FilterMatchMode } from 'primevue/api';
const authStore = useAuthStore();
const uiListItems = authStore.user.uiListItems;
const modelsStore = useModelsStore();
const actionsStore = useActionsStore();
const connectorsStore = useConnectorsStore();
const logsStore = useLogsStore();
const decisionsStore = useDecisionsStore();
const alertStore = useAlertStore();
const { alert } = storeToRefs(alertStore);
const { models, areLoaded } = storeToRefs(modelsStore);
const { actions } = storeToRefs(actionsStore);
const { getLogs } = logsStore;
const { getDecisions } = decisionsStore;
const { getConnectors } = connectorsStore;
const { refreshModelById, getModels, getModel } = modelsStore;
const { getActions } = actionsStore;
const modelPrettyName = ref('Selected Item Details');
const selectedModel = ref();
const toast = useToast();
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
});

onMounted(() => {
    // areLoaded.value = false;
    // getConnectors();
    // getActions();
    // getLogs();
    // getDecisions();
    // getModels();
});

const countActionbyModel = (data) => {
    return actions.value.filter((obj) => obj._modelid === data._id).length;
};

const countInputsbyModel = (data) => {
    return Object.keys(data.attributes.metadata.attributes).length;
};

const importModels = () => {
    console.log('importModels');
    router.push({ path: `/models/up2tommodels` });
};

watch(alert, (newVal) => {
    if (newVal != null) {
        switch (newVal.type) {
            case 'alert-success':
                console.log('alertstore', newVal);
                toast.add({ severity: 'info', summary: 'Model Selected', detail: newVal.message, life: 3000 });
                return;
            case 'alert-danger':
                console.log('alertstore', newVal);
                toast.add({ severity: 'danger', summary: 'Error Detected', detail: newVal.message, life: 3000 });
                return;
        }
        alertStore.clear();
    }
});

const buttonColor = (data) => {
    if (data.attributes.inputsTested && data.attributes.triggersTested) {
        return 'success';
    } else if (!data.attributes.inputsTested && !data.attributes.triggersTested) {
        return 'danger';
    } else {
        return 'warn';
    }
};

// const rowStyle = (data) => {
//     if (data.attributes.inputsTested && data.attributes.triggersTested) {
//         return { color: 'green' };
//     } else if (!data.attributes.inputsTested && !data.attributes.triggersTested) {
//         return { color: 'red' };
//     } else {
//         return { color: 'orange' };
//     }
// };

const onRowSelect = (event) => {
    console.log('model selected', event.data);
    toast.add({ severity: 'info', summary: 'Model Selected', detail: event.data.attributes.name, life: 3000 });
    getModel(event.data._id);
    router.push({ path: `/models/${event.data._id}` });
};

const rowClass = (data) => {
    return [
        {
            'text-red-500': !data.attributes.triggerTested && !data.attributes.inputsTested,
            'text-green-500': data.attributes.triggerTested && data.attributes.inputsTested,
            'text-orange-500': !data.attributes.triggerTested || !data.attributes.inputsTested
        }
    ];
};

const rowStyle = (data) => {
    if (!data.attributes.inputsTested || !data.attributes.triggersTested) {
        return { fontWeight: 'bold' };
    }
};


</script>

<template>
    <div class="col-12">
        <div class="card">
            <DataTable
                v-model:selection="selectedModel"
                v-model:filters="filters"
                :value="models"
                selectionMode="single"
                dataKey="id"
                paginator
                stripedRows
                :rows="uiListItems.modelsList"
                :rowsPerPageOptions="[10, 20, 30]"
                tableStyle="min-width: 50rem"
                filterDisplay="row"
                :loading="!areLoaded"
                :rowClass="rowClass"
                @rowSelect="onRowSelect"
                :globalFilterFields="['id', 'attributes.name', 'attributes.publisher']"
            >
                <template #header>
                    <div class="flex flex-wrap items-center justify-between gap-2">
                        <span class="text-xl font-bold">Models</span>
                        <IconField>
                            <InputIcon>
                                <i class="pi pi-search" />
                            </InputIcon>
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </IconField>

                        <Button label="Active" severity="success" class="mb-2 mr-2" />
                        <Button label="Warnings " severity="warning" class="mb-2 mr-2" />
                        <Button label="Not Ready" severity="danger" class="mb-2 mr-2" />
                        <Button label="Add Models" @click="importModels()" severity="help" class="mb-2 mr-2" />
                    </div>
                </template>

                <template #empty> No Models found - Please Import. </template>
                <template #loading> Loading Models. Please wait. </template>
                <Column field="id" header="Model #" sortable> </Column>
                <Column field="attributes.name" header="Name" sortable>
                    <template #body="{ data }">
                        {{ data.attributes.name.substring(0, 80) }}
                    </template>
                </Column>
                <Column field="attributes.publisher" header="Author" sortable> </Column>
                <Column style="flex: 0 0 3rem" header="Inputs" sortable>
                    <template #body="{ data }">
                        <Tag :value="countInputsbyModel(data)" style="width: 50px; height: 50px" :severity="buttonColor(data)" />
                    </template>
                </Column>
                <Column style="flex: 0 0 3rem" header="Actions" sortable>
                    <template #body="{ data }">
                        <Tag :value="countActionbyModel(data)" style="width: 50px; height: 50px" :severity="buttonColor(data)" />
                    </template>
                </Column>
            </DataTable>
        </div>
    </div>

    <VueJsonPretty :selectedItem="models" :jsonPrettyName="modelPrettyName" />
</template>
