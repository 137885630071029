<script setup>
import { ref, onMounted, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useModelsStore, useAuthStore } from '@/stores';
import { VueJsonPretty } from '@/components';
import { useDateFormat } from '@/composables';
import { useToast } from 'primevue/usetoast';
import { FilterMatchMode } from 'primevue/api';
const authStore = useAuthStore();
const userid = authStore.user.userid;
const up2tomtoken = authStore.user.up2tomtoken;
const modelsStore = useModelsStore();
const { loadingNewModels, modelMessages } = storeToRefs(modelsStore);
const modelPrettyName = ref('Selected Item Details');
const selecteditem = ref({});
const toast = useToast();
const filters = ref({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
});
const metaKey = ref(true);
const cloneDeep = (a) => JSON.parse(JSON.stringify(a));

onMounted(() => {
    modelMessages.value = [];
    console.log('getNewModels ', userid, up2tomtoken);
    modelsStore.getUp2tomModels(userid, up2tomtoken);
});

const createModel = (selecteditem) => {
    let modelNew = {};
    console.log('createModel ', userid, up2tomtoken);
    modelNew = cloneDeep(selecteditem);
    modelNew['userid'] = userid;
    modelNew.attributes.inputsTested = false;
    modelNew.attributes['triggerTested'] = false;
    modelNew.attributes['_decision'] = null;
    modelNew.attributes['decision'] = [];
    modelNew.attributes['response'] = [];
    modelNew.attributes['connector'] = null;
    modelNew.attributes['up2tomtoken'] = up2tomtoken;
  //  modelNew.attributes['up2tomEndpoint'] = 'https://api.up2tom.com/v3/decision/';

    var index = Object.keys(selecteditem.attributes.metadata.attributes).length;
    for (let i = 0; i < index; i++) {
        modelNew.attributes.metadata.attributes[i]['value'] = '';
        modelNew.attributes.metadata.attributes[i]['webhook'] = true;
        modelNew.attributes.metadata.attributes[i]['tested'] = false;
        modelNew.attributes.metadata.attributes[i]['deFormat'] = false;
        modelNew.attributes.metadata.attributes[i]['_connector'] = null;
        modelNew.attributes.metadata.attributes[i]['timesUpdated'] = 0;
    }
    console.log('createModel request is ', modelNew);
    modelsStore.addModel(modelNew);
};

const newModels = computed(() => {
    return modelsStore.up2tommodels.filter(({ id: id1 }) => !modelsStore.models.some(({ id: id2 }) => id2 === id1));
});

const onRowSelect = (event) => {
    console.log('model selected', event.data);
    toast.add({ severity: 'info', summary: 'Model Selected', detail: event.data.attributes.name, life: 3000 });
    let id = selecteditem.value._id;
    //   return router.push({ path: `/models/${id}` });
};

const countOutcomesbyModel = (data) => {
    return Object.keys(data.attributes.metadata.prediction).length;
};

const countInputsbyModel = (data) => {
    return Object.keys(data.attributes.metadata.attributes).length;
};

// watch(alert, (newVal) => {
//     if (newVal != null) {
//         switch (newVal.type) {
//             case 'alert-success':
//                 console.log('alertstore', newVal);
//                 toast.add({ severity: 'info', summary: 'Model Selected', detail: newVal.message, life: 3000 });
//                 return;
//             case 'alert-danger':
//                 console.log('alertstore', newVal);
//                 toast.add({ severity: 'danger', summary: 'Error Detected', detail: newVal.message, life: 3000 });
//                 return;
//         }
//         alertStore.clear();
//     }
// });
</script>

<template>
    <div class="col-12">
        <div class="card">
            <DataTable
                v-model:selection="selecteditem"
                v-model:filters="filters"
                :value="newModels"
                selectionMode="single"
                dataKey="id"
                :metaKeySelection="metaKey"
                paginator
                stripedRows
                :rows="10"
                :rowsPerPageOptions="[10, 20, 50]"
                tableStyle="min-width: 50rem"
                filterDisplay="row"
                :loading="loadingNewModels"
                @rowSelect="onRowSelect"
                :globalFilterFields="['id', 'attributes.name', 'atrributes.publisher']"
            >
                <template #header>
                    <div class="flex flex-wrap items-center justify-between gap-4">
                        <span class="text-xl font-bold">{{ newModels.length }} New Models Available</span>
                        <IconField>
                            <InputIcon>
                                <i class="pi pi-search" />
                            </InputIcon>
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </IconField>
                    </div>
                </template>

                <template #empty> No Models found - Please Refresh. </template>
                <template #loading> Download Models. Please wait. </template>
                <Column field="id" header="Model #" sortable> </Column>
                <Column field="attributes.name" header="Name" sortable>
                    <template #body="{ data }">
                        {{ data.attributes.name.substring(0, 80) }}
                    </template>
                </Column>
                <Column field="attributes.publisher" header="Author" sortable> </Column>
                <Column field="attributes['publish-date]" header="Publised" sortable>
                    <template #body="{ data }">
                        {{ useDateFormat(data.attributes['publish-date']) }}
                    </template>
                </Column>
                <Column header="Inputs" style="flex: 0 0 3rem">
                    <template #body="{ data }">
                        <Tag :value="countInputsbyModel(data)" style="width: 50px; height: 50px" severity="warning" />
                    </template>
                </Column>
                <Column header="Outcomes" style="flex: 0 0 3rem">
                    <template #body="{ data }">
                        <Tag :value="countOutcomesbyModel(data)" style="width: 50px; height: 50px" severity="warning" />
                    </template>
                </Column>
                <Column header="Add" style="flex: 0 0 3rem">
                    <template #body="{ data }">
                        <Button label="Add Model" @click="createModel(data)" severity="help" class="mb-2 mr-2" />
                    </template>
                </Column>
            </DataTable>
        </div>


        <div v-if="modelMessages.length" class="card">
            <transition-group name="p-message" tag="div">
                <Message v-for="msg of modelMessages" :key="msg.id" :severity="msg.severity">{{ msg.content }}</Message>
            </transition-group>
        </div>

    </div>

    <VueJsonPretty v-if="selecteditem != null" :selectedItem="selecteditem" :jsonPrettyName="modelPrettyName" />
</template>
