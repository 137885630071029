import { ref } from 'vue';
import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAuthStore } from '@/stores';
import { router } from '@/router';
const baseUrl = `${import.meta.env.VITE_API_URL}/connectortemplates`;

export const useConnectortemplatesStore = defineStore(
    'connectortemplates',
    () => {
        const connectortemplates = ref([]);
        const connectortemplate = ref({});
        const selectedConnectorcollection = ref({});
        const isLoaded = ref(false);
        const connectortemplateMessages = ref([]);
        let connectortemplateMessageCount = ref(0);
        const connectortemplateActivePanel = ref(0);
        function init() {
            selectedConnectorcollection.value = {};
            postmanID.value = '';
            postmanOK.value = false;
        }
        async function getConnectortemplate(id) {
            isLoaded.value = false;
            try {
                connectortemplate.value = await fetchWrapper.get(`${baseUrl}/${id}`);
                isLoaded.value = true;
            } catch (error) {
                console.error('Failed to refresh connector', error);
            }
        }
        async function getConnectortemplates() {
            isLoaded.value = false;
            try {
                connectortemplates.value = await fetchWrapper.get(`${baseUrl}`);
            } catch (error) {
                showErrorMessage('Failed to fetch connector templates');
            } finally {
                isLoaded.value = true;
            }
        }

        async function deleteConnector() {
            const collectionId = selectedConnectorcollection.value._id;
            try {
                await fetchWrapper.delete(`${baseUrl}/${collectionId}`);
                connectortemplates.value = connectortemplates.value.filter((collection) => collection._id !== collectionId);
                showSuccessMessage('Collection Deleted');
                router.push('/connectortemplates');
            } catch (error) {
                showErrorMessage('Failed to delete collection');
            }
        }
        function showSuccessMessage(message) {
            connectortemplateMessages.value = [
                {
                    severity: 'success',
                    content: message,
                    id: connectortemplateMessageCount.value++
                }
            ];
            connectortemplateActivePanel.value = 0;
        }

        function showErrorMessage(message) {
            connectortemplateMessages.value = [
                {
                    severity: 'error',
                    content: message,
                    id: connectortemplateMessageCount.value++
                }
            ];
            connectortemplateActivePanel.value = 0;
        }
        async function importCollection(postmanID, pmtoken) {
            const authStore = useAuthStore();
            const userid = authStore.user.userid;
            const postman = {
                postmanID: postmanID,
                postmanAPIKey: pmtoken,
                userid
            };
            try {
                const importStatus = await fetchWrapper.post(`${baseUrl}/importcollection`, postman);
                console.log(importStatus);
                const { ok = false, message = 'An Unknown Error Occured' } = importStatus;
                if (ok) {
                    showSuccessMessage(message);
                } else {
                    showErrorMessage(message);
                }
                //  router.push('/connectortemplates');
            } catch (error) {
                console.log('Failed to import collection:', error);
            }
        }
        getConnectortemplates();
        return {
            connectortemplates,
            connectortemplate,
            selectedConnectorcollection,
            getConnectortemplates,
            getConnectortemplate,
            deleteConnector,
            importCollection,
            isLoaded,
            init,
            connectortemplateMessages,
            connectortemplateMessageCount,
            connectortemplateActivePanel
        };
    },
    {
        persist: true
    }
);
