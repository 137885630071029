<script setup>
import { ref } from 'vue';

import AppMenuItem from './AppMenuItem.vue';

const model = ref([
    {
        label: 'Home',
        items: [{ label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' }]
    },
    {
        label: 'UI Components',
        items: [
            { label: 'Connector Templates', icon: 'pi pi-fw pi-server', to: '/connectortemplates' },
            { label: 'Connectors', icon: 'pi pi-fw pi-share-alt', to: '/connectors' },
            { label: 'Models', icon: 'pi pi-prime', to: '/models' },
            { label: 'Decisions', icon: 'pi pi-fw pi-star-fill', to: '/decisions' },
            { label: 'Actions', icon: 'pi pi-spin pi-cog', to: '/actions' },
            { label: 'Action Templates', icon: 'pi pi-fw pi-bolt', to: '/actiontemplates' },
            { label: 'Logs', icon: 'pi pi-fw pi-list', to: '/logs' }
        ]
    },
    {
        label: 'User Options',
        icon: 'pi pi-fw pi-briefcase',
        to: '/pages',
        items: [
            {
                label: 'Auth',
                icon: 'pi pi-fw pi-user',
                items: [
                    {
                        label: 'Login',
                        icon: 'pi pi-fw pi-sign-in',
                        to: '/account/login'
                    },
                    {
                        label: 'Logout',
                        icon: 'pi pi-fw pi-sign-out',
                        to: '/account/logout'
                    },
                    {
                        label: 'Profile',
                        icon: 'pi pi-fw pi-user',
                        to: '/users/profile'
                    }
                ]
            }
        ]
    }
]);
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <app-menu-item v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>
        <li>
            <a href="https://up2tomv7.e2tech.com/" target="_blank">
                <img src="/layout/images/logo-white.png" alt="e2tech e2Integrate" class="w-full mt-3" />
            </a>
        </li>
    </ul>
</template>

<style lang="scss" scoped></style>
