import { defineStore } from 'pinia';
import { fetchWrapper } from '@/helpers';
import { useAuthStore, useAlertStore } from '@/stores';

const baseUrl = `${import.meta.env.VITE_API_URL}/users`;

export const useUsersStore = defineStore(
    'users',
    () => {
        const users = {};
        const user = {};
        async function register(user) {
            await fetchWrapper.post(`${baseUrl}/register`, user);
        }
        async function getAll() {
            // update stored user if the logged in user updated their own record
            const alertStore = useAlertStore();
            try {
                users.value = await fetchWrapper.get(baseUrl);
            } catch (error) {
                alertStore.error(error);
            }
        }
        async function getById(id) {
            const alertStore = useAlertStore();
            try {
                user.value = await fetchWrapper.get(`${baseUrl}/${id}`);
            } catch (error) {
                alertStore.error(error);
            }
        }
        async function update(id, params) {
            await fetchWrapper.put(`${baseUrl}/${id}`, params);
            // update stored user if the logged in user updated their own record
            const authStore = useAuthStore();
            if (id === authStore.user._id) {
                // update local storage
                const user = { ...authStore.user, ...params };
                localStorage.setItem('user', JSON.stringify(user));
                // update auth user in pinia state
                authStore.user = user;
            }
        }
        async function updateUser(id, params) {
            await fetchWrapper.patch(`${baseUrl}/${id}`, params);
            const authStore = useAuthStore();
            // update local storage
            const user = { ...authStore.user, ...params };
            localStorage.setItem('user', JSON.stringify(user));
            // update auth user in pinia state
            authStore.user = user;
        }
        async function _delete(id) {
            await fetchWrapper.delete(`${baseUrl}/${id}`);
            // remove user from list after deleted
            users.value = users.value.filter((x) => x._id !== id);
            // auto logout if the logged in user deleted their own record
            const authStore = useAuthStore();
            if (id === authStore.user._id) {
                authStore.logout();
            }
        }
        return {
            users,
            user,
            register,
            getAll,
            getById,
            update,
            _delete,
            updateUser
        };
    },
    {
        persist: true
    }
);
